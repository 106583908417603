import {
    Button,
    Card, CardActions,
    CardContent, Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import PositionValue from "../../../common/components/PositionValue";
import {useTranslation} from "../../../common/components/LocalizationProvider";


const MonitoringMainDashboardAN420CardComponent = (props) => {
    const classes = props.styleclass;
    const lastPosition = props.lastPosition;

    const t = useTranslation();

    const navigate = useNavigate();

    return (
        <Card variant="outlined">
            <CardContent style={{padding: 0}}>
                <div className={classes.cardContentTitleText}>
                    <Typography variant="h5" component="div">
                        {t('groupNameAN420')}  {/*AN-420*/}
                    </Typography>
                </div>
                <div className={classes.cardContentText}>
                    <Grid container spacing={2} p={2}>
                        <Grid item xs={6}>
                            <Typography color="text.secondary" variant="subtitle1">
                                {t('ioAN420WindDir')}  {/* Wind_dir */}
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"windDirection"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color="text.secondary" variant="subtitle1">
                                {t('ioAN420WindSpeed')}  {/* Wind_speed */}
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"windSpeed"}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    )
};

export default MonitoringMainDashboardAN420CardComponent;
