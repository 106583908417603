import {
    Button,
    Card, CardActions,
    CardContent, Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import PositionValue from "../../common/components/PositionValue";


const MonitoringMainDashboardAspsCardComponent = (props) => {
    const classes = props.styleclass;
    const lastPosition = props.lastPosition;

    const navigate = useNavigate();

    return (
        <Card variant="outlined">
            <CardContent style={{padding: 0}}>
                <div className={classes.outerCardContentTitleText}>
                    <Typography variant="h5" component="div">
                        Group 1
                    </Typography>
                </div>
                <div>
                    <Grid container spacing={1} p={1}>
                        <Grid item xs={6}>
                            <Card variant="outlined" className={classes.cardMarginBottom}>
                                <CardContent style={{padding: 0}}>
                                    <div className={classes.cardContentTitleText}>
                                        <Typography variant="h5" component="div">
                                            Solar panel
                                        </Typography>
                                    </div>
                                    <div>
                                        <Grid container spacing={1} p={1}>
                                            <Grid item xs={12}>
                                                <Typography color="text.secondary" variant="subtitle1">
                                                    outputPower
                                                </Typography>
                                                <Divider/>
                                                <Typography variant="body1">
                                                    <PositionValue position={lastPosition} attribute={"io102"}/>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" className={classes.cardMarginBottom}>
                                <CardContent style={{padding: 0}}>
                                    <div className={classes.cardContentTitleText}>
                                        <Typography variant="h5" component="div">
                                            System Record
                                        </Typography>
                                    </div>
                                    <div>
                                        <Grid container spacing={1} p={1}>
                                            <Grid item xs={12}>
                                                <Typography color="text.secondary" variant="subtitle1">
                                                    outputPower
                                                </Typography>
                                                <Divider/>
                                                <Typography variant="body1">
                                                    <PositionValue position={lastPosition} attribute={"io305"}/>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" className={classes.cardMarginBottom}>
                                <CardContent style={{padding: 0}}>
                                    <div className={classes.cardContentTitleText}>
                                        <Typography variant="h5" component="div">
                                            Output Energy SH2
                                        </Typography>
                                    </div>
                                    <div>
                                        <Grid container spacing={1} p={1}>
                                            <Grid item xs={12}>
                                                <Typography color="text.secondary" variant="subtitle1">
                                                    outputPower
                                                </Typography>
                                                <Divider/>
                                                <Typography variant="body1">
                                                    <PositionValue position={lastPosition} attribute={"io404"}/>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" className={classes.cardMarginBottom}>
                                <CardContent style={{padding: 0}}>
                                    <div className={classes.cardContentTitleText}>
                                        <Typography variant="h5" component="div">
                                            Switches
                                        </Typography>
                                    </div>
                                    <div className={classes.cardContentText}>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography color="text.secondary" variant="subtitle1">
                                                                S2
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">
                                                                <PositionValue position={lastPosition}
                                                                               attribute={"io1402"}/>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography color="text.secondary" variant="subtitle1">
                                                                S3
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">
                                                                <PositionValue position={lastPosition}
                                                                               attribute={"io1403"}/>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography color="text.secondary" variant="subtitle1">
                                                                S4
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">
                                                                <PositionValue position={lastPosition}
                                                                               attribute={"io1404"}/>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography color="text.secondary" variant="subtitle1">
                                                                S5
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">
                                                                <PositionValue position={lastPosition}
                                                                               attribute={"io1405"}/>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography color="text.secondary" variant="subtitle1">
                                                                S6
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">
                                                                <PositionValue position={lastPosition}
                                                                               attribute={"io1406"}/>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography color="text.secondary" variant="subtitle1">
                                                                S7
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">
                                                                <PositionValue position={lastPosition}
                                                                               attribute={"io1407"}/>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card variant="outlined" className={classes.cardMarginBottom}>
                                <CardContent style={{padding: 0}}>
                                    <div className={classes.cardContentTitleText}>
                                        <Typography variant="h5" component="div">
                                            Wind generator
                                        </Typography>
                                    </div>
                                    <div>
                                        <Grid container spacing={1} p={1}>
                                            <Grid item xs={12}>
                                                <Typography color="text.secondary" variant="subtitle1">
                                                    outputPower
                                                </Typography>
                                                <Divider/>
                                                <Typography variant="body1">
                                                    <PositionValue position={lastPosition} attribute={"io204"}/>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" className={classes.cardMarginBottom}>
                                <CardContent style={{padding: 0}}>
                                    <div className={classes.cardContentTitleText}>
                                        <Typography variant="h5" component="div">
                                            Battery
                                        </Typography>
                                    </div>
                                    <div>
                                        <Grid container spacing={1} p={1}>
                                            <Grid item xs={12}>
                                                <Typography color="text.secondary" variant="subtitle1">
                                                    SoC
                                                </Typography>
                                                <Divider/>
                                                <Typography variant="body1">
                                                    <PositionValue position={lastPosition} attribute={"io615"}/>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" className={classes.cardMarginBottom}>
                                <CardContent style={{padding: 0}}>
                                    <div className={classes.cardContentTitleText}>
                                        <Typography variant="h5" component="div">
                                            Brown Energy SH3
                                        </Typography>
                                    </div>
                                    <div>
                                        <Grid container spacing={1} p={1}>
                                            <Grid item xs={12}>
                                                <Typography color="text.secondary" variant="subtitle1">
                                                    outputPower
                                                </Typography>
                                                <Divider/>
                                                <Typography variant="body1">
                                                    <PositionValue position={lastPosition} attribute={"io504"}/>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" className={classes.cardMarginBottom}>
                                <CardContent style={{padding: 0}}>
                                    <div className={classes.cardContentTitleText}>
                                        <Typography variant="h5" component="div">
                                            Loads
                                        </Typography>
                                    </div>
                                    <div className={classes.cardContentText}>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography color="text.secondary" variant="subtitle1">
                                                                L1
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">
                                                                <PositionValue position={lastPosition}
                                                                               attribute={lastPosition.attributes.io1407 ? "io1407" : "io1402"}/>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography color="text.secondary" variant="subtitle1">
                                                                L2
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">
                                                                <PositionValue position={lastPosition}
                                                                               attribute={lastPosition.attributes.io1407 ? "io1407" : "io1403"}/>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography color="text.secondary" variant="subtitle1">
                                                                L3
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">
                                                                <PositionValue position={lastPosition}
                                                                               attribute={lastPosition.attributes.io1407 ? "io1407" : "io1403"}/>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography color="text.secondary" variant="subtitle1">
                                                                L4
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">
                                                                <PositionValue position={lastPosition}
                                                                               attribute={lastPosition.attributes.io1407 ? "io1407" : "io1405"}/>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button size="small" onClick={() => navigate('/monitoring/aspsdashboard')}>
                    Detailed view
                </Button>
            </CardActions>
        </Card>
    )
};

export default MonitoringMainDashboardAspsCardComponent;
