import {
    Button,
    Card, CardActions,
    CardContent, Divider,
    Grid,
    Typography
} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import PositionValue from "../../../common/components/PositionValue";
import {useTranslation} from "../../../common/components/LocalizationProvider";


const MonitoringMainDashboardInclinationCardComponent = (props) => {
    const classes = props.styleclass;
    const lastPosition = props.lastPosition;

    const t = useTranslation();

    const navigate = useNavigate();

    return (
        <Card variant="outlined">
            <CardContent style={{padding: 0}}>
                <div className={classes.cardContentTitleText}>
                    <Typography variant="h5" component="div">
                        {t('groupNameInclination')}  {/*Inclination*/}
                    </Typography>
                </div>
                <div className={classes.cardContentText}>
                    <Grid container spacing={2} p={2}>
                        <Grid item xs={6}>
                            <Typography color="text.secondary" variant="subtitle1">
                                {t('ioInclinationChX')}  {/* Ch_X */}
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"inclinationX"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color="text.secondary" variant="subtitle1">
                                {t('ioInclinationChY')}  {/* Ch_Y */}
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"inclinationY"}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    )
};

export default MonitoringMainDashboardInclinationCardComponent;
